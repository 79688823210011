













































import { Component, PropSync, Vue } from 'vue-property-decorator';
import StrategyModerateActivationDialogViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/hiring-flow/strategy-moderate-activation-dialog-view-model';

@Component({
  name: 'StrategyModerateHiringFlowDialog',
  components: {
    StrategyComparisonPesosAndModerate: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyComparisonPesosAndModerate.vue'),
    StrategyInformationAndVideo: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyInformationAndVideo.vue'),
    StrategyRiskTolerance: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyRiskTolerance.vue'),
    StrategyModerateWarningChangeStrategy: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyModerateWarningChangeStrategy.vue'),
    StrategyHiring: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyHiring.vue'),
    StrategyTransfer: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyTransfer.vue'),
    StrategyRequestSent: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyRequestSent.vue'),
  },
})
export default class StrategyModerateHiringFlowDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @PropSync('investorGoalId', { type: String, required: true })
  synced_investor_goal_id!: string;

  @PropSync('goalName', { type: String, required: true })
  synced_goal_name!: string;

  strategy_moderate_activation_dialog_model = Vue.observable(
    new StrategyModerateActivationDialogViewModel(this),
  );

  closeModal() {
    this.synced_is_open = false;
    this.$emit('closeModal');
  }

  destroyed() {
    this.strategy_moderate_activation_dialog_model.resetStateValues();
  }
}
